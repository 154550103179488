import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useLocation } from '@reach/router';
import * as style from './Link.module.css';
import useLang from 'src/hooks/useLang';

const linkVariation = cva('', {
  variants: {
    size: {
      sm: 'text-base px-4 py-1',
      md: 'text-base px-4 py-2',
      lg: 'text-base px-4 py-3',
      xl: 'text-base px-4 py-4',
    },
    variant: {
      header: '',
    },
    isActive: {
      true: style.isActive,
    },
    isRevertedColor: {
      true: style.revertColors,
    },
  },
});

type Props = {
  isHeading?: boolean;
  subLink?: string[];
} & ComponentPropsWithoutRef<'a'> &
  VariantProps<typeof linkVariation>;

const Link: FC<Props> = ({
  children,
  className,
  variant,
  href = '',
  size = 'md',
  isRevertedColor,
  subLink = [],
  ...props
}) => {
  const lang = useLang();
  const path = `${lang === 'en' ? '' : '/' + lang}${href}`;
  const isActive = useIsActive(variant === 'header', path, subLink);

  return (
    <a
      {...props}
      href={path}
      className={linkVariation({
        size,
        className,
        variant,
        isActive,
        isRevertedColor: !isActive && isRevertedColor,
      })}
    >
      {children}
    </a>
  );
};

const useIsActive = (isHeading: boolean, href: string, subLink?: string[]) => {
  const { pathname, hash } = useLocation();

  if (!isHeading) {
    return false;
  }

  return (
    href === `${pathname}${hash}` || subLink?.includes(`${pathname}${hash}`)
  );
};

export default Link;
