import { useLocation } from '@reach/router';

const useLang = (): 'ar' | 'en' => {
  const { pathname } = useLocation();
  const isAr = pathname.split('/').includes('ar');

  return isAr ? 'ar' : 'en';
};

export default useLang;
