import { ComponentPropsWithoutRef, FC } from 'react';

const Container: FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={[
        '2xl:w-[98%] 2xl:max-w-[1836px] xl:max-w-7xl lg:max-w-5xl lg:px-16 md:px-14 px-6 mx-auto w-full',
        className,
      ].join(' ')}
    >
      {children}
    </div>
  );
};

export default Container;
