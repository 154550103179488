import { FC, ReactNode, useEffect } from 'react';

import Header, { HeaderProps } from './Header';
import Footer from './Footer';
import useLang from 'src/hooks/useLang';
import { useLocation } from '@reach/router';
// import Cursor from '@atoms/Cursor';
// import Splash from './Splash';
import { LazyMotion, domAnimation } from 'framer-motion';

type Props = { children: ReactNode; headerProps?: HeaderProps };

const Layout: FC<Props> = ({ children, headerProps }) => {
  const lang = useLang();
  const { hash, pathname, search } = useLocation();
  const fullpath = `${pathname}/#${hash}?${search}`;

  useEffect(() => {
    if (typeof window !== 'undefined' && !hash) window.scrollTo(0, 0);
  }, [fullpath]);

  return (
    <div className="overflow-hidden" dir={lang === 'ar' ? 'rtl' : 'ltr'}>
      <LazyMotion features={domAnimation} strict>
        {/* <Cursor /> */}
        <Header {...headerProps} />
        <main>{children}</main>

        <Footer />
        {/* <Splash /> */}
      </LazyMotion>
    </div>
  );
};

export default Layout;
