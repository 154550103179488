const links = [
  { url: '/', name_en: 'Home', name_ar: 'الرئيسية' },
  {
    url: '/advisory-solutions/',
    name_en: 'Advisory Solutions',
    name_ar: 'خدماتنا',
    links: [
      {
        url: '/cyber-security-audit-solution/',
        name_en: 'Cyber Security Audit Solution',
        name_ar: 'خدماتنا',
      },
      {
        url: '/cyber-maturity-assessment/',
        name_en: 'Cyber Maturity Assessment',
        name_ar: 'cyber-maturity-assessment',
      },
      // {
      //   url: '/compromise-assessments/',
      //   name_en: 'Compromise assessments',
      //   name_ar: 'Compromise assessments',
      // },
    ],
  },
  {
    url: '/offensive-security-testing/',
    name_en: 'Offensive Security Testing',
    name_ar: 'Offensive Security Testing',
    links: [
      {
        url: '/offensive-cloud-security/',
        name_en: 'Offensive Cloud Security',
        name_ar: 'خدماتنا',
      },
      {
        url: '/compromise-assessments/',
        name_en: 'Compromise assessments',
        name_ar: 'Compromise assessments',
      },
    ],
  },
  {
    url: '/managed-security-solutions/',
    name_en: 'Managed Security Solutions',
    name_ar: 'خدماتنا',
  },
  { url: '/about/', name_en: 'About us', name_ar: 'عنا' },
  {
    url: '/governance-sport-industry-solutions/',
    name_en: 'Governance sport industry solutions',
  },
];

export default links;
