exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-advisory-solutions-tsx": () => import("./../../../src/pages/advisory-solutions.tsx" /* webpackChunkName: "component---src-pages-advisory-solutions-tsx" */),
  "component---src-pages-compromise-assessments-tsx": () => import("./../../../src/pages/compromise-assessments.tsx" /* webpackChunkName: "component---src-pages-compromise-assessments-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cyber-maturity-assessment-tsx": () => import("./../../../src/pages/cyber-maturity-assessment.tsx" /* webpackChunkName: "component---src-pages-cyber-maturity-assessment-tsx" */),
  "component---src-pages-cyber-security-audit-solution-tsx": () => import("./../../../src/pages/cyber-security-audit-solution.tsx" /* webpackChunkName: "component---src-pages-cyber-security-audit-solution-tsx" */),
  "component---src-pages-governance-sport-industry-solutions-tsx": () => import("./../../../src/pages/governance-sport-industry-solutions.tsx" /* webpackChunkName: "component---src-pages-governance-sport-industry-solutions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-managed-security-solutions-tsx": () => import("./../../../src/pages/managed-security-solutions.tsx" /* webpackChunkName: "component---src-pages-managed-security-solutions-tsx" */),
  "component---src-pages-offensive-cloud-security-tsx": () => import("./../../../src/pages/offensive-cloud-security.tsx" /* webpackChunkName: "component---src-pages-offensive-cloud-security-tsx" */),
  "component---src-pages-offensive-security-testing-tsx": () => import("./../../../src/pages/offensive-security-testing.tsx" /* webpackChunkName: "component---src-pages-offensive-security-testing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-responsible-disclosure-tsx": () => import("./../../../src/pages/responsible-disclosure.tsx" /* webpackChunkName: "component---src-pages-responsible-disclosure-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

