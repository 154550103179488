import {
  ComponentPropsWithoutRef,
  ElementType,
  ReactNode,
  useMemo,
} from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import * as style from './Text.module.css';

const textVariation = cva('', {
  variants: {
    size: {
      small: 'text-sm',
      paragraph: 'text-base lg:text-lg',
      subHeading: style.subHeading,
      heading: style.heading,
      hero:
        'text-5xl font-bold text-invert leading-normal md:text-7xl 2xl:text-8xl',
    },
    color: {
      primary: 'text-primary',
      secondary: 'text-secondary',
      base: 'text-base',
      invert: 'text-invert',
      inherit: 'text-inherit',
      none: '',
    },
  },
});

type Props<T extends ElementType> = {
  children: ReactNode;
  as?: ElementType;
  asHtml?: boolean;
} & ComponentPropsWithoutRef<T> &
  VariantProps<typeof textVariation>;

const defaultElement = 'p';

const Text = <T extends ElementType = typeof defaultElement>({
  className,
  asHtml = false,
  children,
  color = 'inherit',
  size = 'paragraph',
  as: As,
  ...props
}: Props<T>): JSX.Element => {
  const Element = useMemo(() => As || defaultElement, [As]);

  return (
    <Element
      dangerouslySetInnerHTML={asHtml ? { __html: children } : undefined}
      className={textVariation({ className, color, size })}
      {...props}
    >
      {asHtml ? null : children}
    </Element>
  );
};

export default Text;
