import './src/styles/global.css';

import Layout from 'src/components/Layout';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onClientEntry = () => {
  const splash = document.getElementById('splash');

  if (splash) {
    console.log('onClientEntry');
    splash.classList.add('flex');
    splash.classList.remove('hidden');
  }
};
export const onPreRouteUpdate = () => {
  const splash = document.getElementById('splash');

  if (splash) {
    console.log('onPreRouteUpdate');
    splash.classList.add('flex');
    splash.classList.remove('hidden');
  }
};
export const onRouteUpdateDelayed = () => {
  const splash = document.getElementById('splash');

  if (splash) {
    console.log('onRouteUpdateDelayed');
    splash.classList.add('flex');
    splash.classList.remove('hidden');
  }
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    const splash = document.getElementById('splash');
    if (splash) {
      console.log('onRouteUpdate');
      splash.classList.add('!hidden');
      splash.classList.remove('flex');
    }
  }, 1000);
};
