import Container from '@atoms/Container';
import Link from '@atoms/Link';
import Text from '@atoms/Text';
import FacebookIcon from '@icons/facebook';
import LinkedinIcon from '@icons/linkedin';
import MailIcon from '@icons/mail';
import PhoneIcon from '@icons/phone';
import TwitterIcon from '@icons/twitter';
import links from 'src/constants/links';
import social from 'src/constants/social';

const Footer = () => {
  return (
    <footer className="text-white pt-section pb-sectionLg lg:py-section bg-[#0c1e27]/80 backdrop-blur-md relative z-50">
      <Container className="flex gap-10 flex-col md:flex-row justify-between">
        <div>
          <div className="flex mb-10 flex-wrap justify-center lg:justify-between items-center xl:flex-nowrap gap-8">
            <div className="flex">
              <div className="max-w-[216px] lg:max-w-[450px]:">
                <img src="/logo-white.png" alt="NABD" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-10 mt-4">
          <div>
            <Text
              className="text-center md:text-start !text-white"
              size="subHeading"
            >
              Explore
            </Text>
            <nav>
              <ul className="grid md:grid-cols-2 gap-x-12 justify-center md:justify-start">
                {links
                  .map(link => [link, ...(link?.links || [])])
                  .flat()
                  .map(link => (
                    <li>
                      <Link
                        href={link.url}
                        className="!px-0 !pt-4 block"
                        isRevertedColor
                      >
                        {link.name_en}
                      </Link>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
          <div>
            <Text
              className="text-center md:text-start !text-white"
              size="subHeading"
            >
              Contact
            </Text>
            <nav>
              <ul className="flex flex-col gap-x-12 justify-center md:justify-start">
                {[
                  {
                    text: 'LinkedIn',
                    link: social.linkedin,
                    type: 'link',
                  },
                  {
                    text: 'Twitter',
                    link: social.twitter,
                    type: 'link',
                  },
                  { text: social.email, link: social.email, type: 'mail' },
                  { text: social.mobile, link: social.mobile, type: 'mobile' },
                ].map(({ link, type, text }) => (
                  <li>
                    <Link
                      href={
                        type === 'mobile'
                          ? `tel:${link}`
                          : type === 'mail'
                          ? `mailto:${link}`
                          : link
                      }
                      className="!px-0 !pt-4 block"
                      isRevertedColor
                    >
                      {text}
                    </Link>
                  </li>
                ))}

                <li>
                  2930 Northern Ring Road - Al-Wadi Dist. Unit No. 96 Riyadh, KSA 6137 - 13313
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </Container>

      <Container className="grid md:grid-cols-2 gap-10 mt-10">
        <div className="flex gap-3 items-center">
          <span>©NABD {new Date().getFullYear()} | </span>
          <Link href="/privacy-policy/" className="!px-0 block" isRevertedColor>
            Privacy Policy
          </Link>
        </div>
        <div data-nosnippet className="flex gap-5 justify-center md:justify-start">
          {[
            'cert01.png',
            'cert02.svg',
            'cert03.svg',
            'cert04.png',
            'cert05.svg',
          ].map(logo => (
            <img src={`/certifications/${logo}`} key={logo} alt={logo} />
          ))}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
