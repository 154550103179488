import { VariantProps, cva } from 'class-variance-authority';
import {
  ReactNode,
  FC,
  ComponentPropsWithoutRef,
  useRef,
  useEffect,
} from 'react';
import * as style from './Modal.module.css';
import Text from '@atoms/Text';
import Button from '@atoms/Button';
import { AnimatePresence, m } from 'framer-motion';
import ReactDom from 'react-dom';
import { IconX } from '@tabler/icons-react';

const modalVariation = cva(style.modal, {
  variants: {
    placement: {
      // top: '',
      start: style.start,
      // end: '',
      // center: '',
      // bottom: '',c
    },
  },
});

const createElement = (id?: string) => {
  if (typeof window === 'undefined' || !id) return null;

  let modalWrapper = document.getElementById(id);
  if (!!modalWrapper) return modalWrapper;

  modalWrapper = document.createElement('div');
  modalWrapper.id = id;

  document.body.appendChild(modalWrapper);
  return modalWrapper;
};

const modalWrapper = createElement('modal-wrapper');

export type Props = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  outterClassName?: string;
  hideHeader?: boolean;
} & ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof modalVariation>;
const Modal: FC<Props> = ({
  className,
  outterClassName,
  children,
  open,
  onClose,
  placement = 'start',
  title,
  hideHeader = false,
}) => {
  const nestedWrapper = useRef<HTMLElement | null>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const element = createElement('nested');
      nestedWrapper.current = element;
      modalWrapper?.appendChild(element as HTMLElement);

      return () => {
        modalWrapper?.removeChild(element as HTMLElement);
      };
    }
  }, []);

  const modal = (
    <>
      {open && (
        <style global jsx>{`
          body {
            overflow: hidden;
          }
        `}</style>
      )}
      <AnimatePresence>
        {open && (
          <m.div
            transition={{ duration: 0.5 }}
            className={modalVariation({
              className: outterClassName,
              placement,
            })}
          >
            <m.div
              className={className}
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -10, opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {!hideHeader && (
                <header className="flex justify-between px-4 mb-8 py-2">
                  <Text color="inherit" size={'subHeading'}>
                    {title}
                  </Text>
                  <Button
                    variant="custom"
                    className="bg-transparent mt-3"
                    onClick={onClose}
                  >
                    <IconX />
                  </Button>
                </header>
              )}
              {children}
            </m.div>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );

  if (!nestedWrapper.current) return <></>;
  return ReactDom.createPortal(modal, nestedWrapper.current);
};

export default Modal;
