import { VariantProps, cva } from 'class-variance-authority';
import { ElementType, ComponentPropsWithoutRef, FC, ReactNode } from 'react';

const buttonVariation = cva('inline-block rounded-md', {
  variants: {
    size: {
      sm: 'text-white px-4 py-1',
      md: 'text-white px-4 py-2',
      lg: 'text-white px-4 py-3',
      xl: 'text-white px-4 py-4',
    },
    variant: {
      secondary: '',
      primary: '',
      black: '',
      white: '',
      custom: '',
    },
    display: {
      outlined: '',
      flat: '',
      transparent: '',
    },
  },
  compoundVariants: [
    // flat
    {
      variant: 'primary',
      display: 'flat',
      className: 'bg-primary text-white',
    },
    {
      variant: 'secondary',
      display: 'flat',
      className: 'bg-secondary text-white',
    },
    {
      variant: 'black',
      display: 'flat',
      className: 'bg-black text-white',
    },
    // transparent
    {
      variant: 'primary',
      display: 'transparent',
      className: 'bg-transparent text-primary',
    },
    {
      variant: 'secondary',
      display: 'transparent',
      className: 'bg-transparent text-secondary',
    },
    {
      variant: 'black',
      display: 'transparent',
      className: 'bg-transparent text-black',
    },
    {
      variant: 'white',
      display: 'transparent',
      className: 'bg-transparent text-white',
    },
    // outlined
    {
      variant: 'primary',
      display: 'outlined',
      className: 'bg-transparent text-primary',
    },
    {
      variant: 'secondary',
      display: 'outlined',
      className: 'bg-transparent text-secondary',
    },
    {
      variant: 'black',
      display: 'outlined',
      className: 'bg-transparent text-black',
    },
  ],
});

type ExtraProps<T extends ElementType> = {
  children: ReactNode;
  as?: T;
} & VariantProps<typeof buttonVariation>;
export type Props<T extends ElementType> = ExtraProps<T> &
  Omit<ComponentPropsWithoutRef<T>, keyof ExtraProps<T>>;

const defaultElement = 'button';

const Button = <T extends ElementType = typeof defaultElement>({
  className,
  children,
  size = 'md',
  variant = 'primary',
  as,
  display = 'flat',
  ...props
}: Props<T>) => {
  const Comp = as || defaultElement;
  return (
    <Comp
      {...props}
      className={buttonVariation({ className, size, variant, display })}
    >
      {children}
    </Comp>
  );
};

export default Button;
