import Container from '@atoms/Container';
import Link from '@atoms/Link';
import links from 'src/constants/links';
import useLang from 'src/hooks/useLang';
import { useState } from 'react';
import Modal from '../Modal';
import Button from '@atoms/Button';
import MenuIcon from '@icons/menu';

const MobileHeader = ({}) => {
  const [open, setOpen] = useState(false);

  const lang = useLang();

  return (
    <>
      <Container className="flex items-center justify-between">
        <Link href="/" className="w-32 !px-0">
          <img src="../../logo.png" alt="NABD" />
        </Link>

        <div className="flex gap-2">
          <Button
            onClick={() => setOpen(true)}
            display="transparent"
            className="flex items-center gap-2"
            variant="black"
            aria-label="open menu"
          >
            <MenuIcon className="mix-blend-difference" />
          </Button>
        </div>
        <Modal
          className="!bg-black !text-white"
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className="flex justify-center pt-sectionLg px-20 flex-col gap-8">
            {links.map(({ url, ...link }) => (
              <div key={url}>
                <Link
                  isRevertedColor
                  className="text-[10vw] !py-[2vw]"
                  key={url}
                  href={url}
                >
                  {link[`name_${lang}`]}
                </Link>

                {!!link.links?.length && (
                  <div className="flex justify-center px-10 flex-col">
                    {link.links.map(sub => (
                      <Link
                        isRevertedColor
                        className="text-[10vw] !py-[2vw]"
                        key={sub.url}
                        href={sub.url}
                      >
                        {sub[`name_${lang}`]}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default MobileHeader;
