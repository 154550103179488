import loadable from '@loadable/component';
import MobileHeader from './MobileHeader';
import { FC, useRef } from 'react';
import useScroll from 'src/hooks/useScroll';
const DesktopHeader = loadable(() => import('./DesktopHeader'));

type Props = {};
const Header: FC<Props> = () => {
  const scroll = useScroll()
  const prevScrollRef = useRef(0)

  const scrolledUp = prevScrollRef.current > scroll
  prevScrollRef.current = scroll

  return (
    <header className={[scrolledUp? "fixed" : "absolute", "top-0 w-full z-50", scroll > 100 && "backdrop-blur-sm"].join(" ")}>
      <div className="hidden xl:block">
        <DesktopHeader />
      </div>
      <div className="block xl:hidden">
        <MobileHeader />
      </div>
    </header>
  );
};

export default Header;
export type { Props as HeaderProps };
