const social = {
  twitter: 'https://twitter.com/NabdDigital',
  linkedin: 'https://www.linkedin.com/company/nabdsolutions',
  mobile: '+966547366217',
  email: 'info@nabdsolutions.com',
  whatsapp: ' ',
  mapLink: ' ',
};

export default social;
